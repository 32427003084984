import React, { useState, useContext, useEffect } from "react";
import { CartContext } from '../../context/cart'
import lapisIcon from '../../images/png/ferramenta-lapis.png'
import api from "../../services/api";
import ReactLoading from 'react-loading';

const TableListDesktop = ({ name, image, price,promotion_price, id, zipCode, cart,free_shipping }) => {
    const { handleStateCart,handleShippmentCartValue,setPrice } = useContext(CartContext)
    const [shippementValue, setShippementValue] = useState()
    const [animation, setAnimation] = useState(false)

    const getShippmentValue = async () => {
        setAnimation(true)
        const reduceWeight = cart.reduce(
            (acc, item) => acc + Number(item.weight) * Number(item.quantity),
            0
        );



        const response = await api.post("/cart/get/shippement/value/", {
            "weight": reduceWeight,
            "free_shipping":free_shipping,
            "zipCode": zipCode ? zipCode : localStorage.getItem("@PRIMARY_ADDRESS")
        })
        

        if (response.status == 200) {
            setTimeout(() => {
                setAnimation(false)

            },[1500])


            if(response.data.type_delivery == "1"){

                const total = cart.reduce((acc, item) => {
                    const itemPrice = item.promotion_price && item.promotion_price !== "0" 
                        ? Number(item.promotion_price) 
                        : Number(item.price);
                    return acc + itemPrice * item.quantity;
                }, 0);
     

                setShippementValue(response.data.vlTotal )

                const itemsWithoutFreeShipping = cart.filter(item => item.free_shipping === "Não");

                // Calculando o valor do envio multiplicado pelo total
                const shipmentValue = response.data.vlTotal * itemsWithoutFreeShipping.length;

                // Chamando o método handleShippmentCartValue
                handleShippmentCartValue(shipmentValue);
                setPrice(shipmentValue + total)

                
                return        
            }

            const itemsWithoutFreeShipping = cart.filter(item => item.free_shipping === "Não");

            // Calculando o valor do envio multiplicado pelo total
            const shipmentValue = response.data.vlTotal * itemsWithoutFreeShipping.length;

            // Chamando o método handleShippmentCartValue
            handleShippmentCartValue(shipmentValue);
            setPrice(shipmentValue)

        }

    }

    useEffect(() => {
        getShippmentValue()


    }, [id,zipCode])

    return (
    

            <tr>
                <th className='image_table_th'><img src={image} className='image_table_payment' /></th>
                <th scope="row">{name}</th>
                <th scope="row">{promotion_price != "0" ? promotion_price : price}</th>
                <th>{ animation == true ? <ReactLoading type={"spin"} color='black' width={20} /> : Number(shippementValue).toLocaleString("pt-br", { minimumFractionDigits: 2,maximumFractionDigits:2 })}</th>
                <td onClick={() => handleStateCart(true)} style={{width:"30px",cursor:"pointer"}}> <img src={lapisIcon} style={{width:"18px",height:"18px"}} /></td>
            </tr>
    )
}

export default TableListDesktop