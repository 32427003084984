import React from 'react'
import Container from '../../components/Container'
import Header from '../../components/Header'
import HeaderMobile from '../../components/HeaderMobile'
import HeaderSecondary from '../../components/HeaderSecondary'
import ContainerPayment from '../../components/ContainerPayment'
import SidebarCart from '../../components/SidebarCart'
import PaymentIcons from '../../components/PaymentIcons'
import SidebarFilter from '../../components/SidebarFilterTest'

const Payment = () => {
    return (
    
        <Container>
            
            <Header />
            <HeaderMobile />
            <HeaderSecondary />
            <PaymentIcons type={1} />
            <ContainerPayment />
            <SidebarCart />
            <SidebarFilter />
        </Container>
    )
}

export default Payment