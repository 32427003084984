import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { CartContext } from '../../context/cart'
import { useNavigate } from 'react-router-dom'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import InputCupom from '../InputCupom'
import TableMobile from '../TableMobile'
import { motion } from 'framer-motion'
import TableListDesktop from '../TableListDesktop'
import lapisIcon from '../../images/png/ferramenta-lapis.png'
import ReactLoading from 'react-loading';

const CardWrapper = styled.div`
    display: none;
    margin-top: 0rem;
    min-height: 190px;
    overflow-y: auto;
    margin-bottom: 0rem;

    @media screen and (max-width:720px) {
    display: block;
    margin-top: 0.9rem;
    height: 290px;
    overflow-y: auto;
    margin-bottom: 0rem;
        
    }
`
const CardWrapperCep = styled.div`
    display: flex;
    width: auto;
    align-items: center;

    .primary{
        color: black;
        font-family: "Poppins", serif;
        font-weight: 500;
        font-style: normal;        
    }

    .secondary{
        cursor: pointer;
        color: #3d71f8;
        font-family: "Poppins", serif;
        font-weight: 400;
        margin-left: 0.9rem;
        font-style: normal;      
    }
    
    @media screen  and (max-width:720px){
        width: 90%;
        flex-direction: column;
        align-items: center;
    }

 
`
const CardTable = styled.div`

    display: block;

    @media screen and (max-width:720px){
        table{
            display: none;
        }
    }

`
const ClearMobile = styled.div`

    @media screen and (max-width:720px){
        height: 1.2rem;
    }

`

const ContainerPayment = () => {


    const navigate = useNavigate()

    const { cart, price, cep, shippmentCartValue, handleStateCart, handleFreight, priceFreight, priceAddress, street, userSelectState, userSelectCity, setUserSelectCity, setUserSelect, setNumber, setStreet, number, setComplement } = useContext(CartContext)

    const [brazilianStates, setBrazilianStates] = useState([])
    const [brazilianCitys, setBrazilianCitys] = useState([])
    const [address,setAddress] = useState([])
    const [selectAddress,setSelectAddress] = useState()
    const [selectZipCode,setSelectZipCode] = useState()
    const [animation,setAnimation] = useState(true)
    const handleBtnBuy = () => {
        navigate("/")
    }

    const handleBtnContinue = () => {

        navigate("/payment/identification")

    }
    const getAddress = async () => {
        const response = await api.get(`addresses/get`);
        if(response.status == 404){
            setAddress([])
            return false
        }
        localStorage.setItem("@PRIMARY_ADDRESS",response.data[0].zipCode)
        setAddress(response.data)
        setSelectAddress(`${response.data[0].street}, ${response.data[0].number} - ${response.data[0].city} - ${response.data[0].state}`)
        setSelectZipCode(response.data[0].zipCode)

    }
    const handleSelectAddress = (street,zipCode,number,city,state) => {
        setSelectAddress(street)
        setSelectZipCode(zipCode)
        setSelectAddress(`${street}, ${number} - ${city} - ${state}`)

    }
    useEffect(() => {
        getAddress()
        handleStateCart(false)
    }, [])

    useEffect(() => {
        const getBrazilianStates = async () => {
            const response = await axios.get(
                "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
            );

            setBrazilianStates(response.data);
        };

        getBrazilianStates();
    }, []);


    useEffect(() => {

        setTimeout(() => {

            setAnimation(false)
        },[1500])

    },[])

    useEffect(() => {
        const getCitys = async () => {
            const response = await axios.get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${userSelectState}/distritos`
            );


            setBrazilianCitys(response.data)
        }
        getCitys()

    }, [userSelectState])



    return (
        <motion.div
            animate={{
                x: [0, 20, 0],
                transition: { ease: ["easeIn", "easeOut"] }
            }}
        >
            <div className='container_payment'>

                <ToastContainer style={{ zIndex: 999999 }} />
                <div className='box_container_payment'>
                    <div className='container_primary_payment'>
                        <p style={{textAlign:"left"}}>Carrinho de produtos</p>
                        <CardWrapperCep>
                            <p className='primary'>Endereço: {selectAddress}  </p>
                            <div class="dropdown">
                                
                            <p className='secondary'  id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Editar</p>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {address?.map(item => {
                                    return(
                                        <a class="dropdown-item" href="#" onClick={() => handleSelectAddress(item.street,item.zipCode,item.number,item.city,item.state)}>{item.street}</a>
                                    )
                                })}
                           </div>
                            </div>

                        </CardWrapperCep>

                        <CardTable>
                        <table  class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Imagem</th>
                                    <th scope="col">Produto</th>
                                    <th scope="col">Preço</th>
                                    <th scope="col">Frete </th>
                                    <th scope="col">Editar</th>

                                </tr>
                            </thead>
                            <tbody>
                                {cart?.map(item => {
                                    console.log(item)
                                    return (

                                        <TableListDesktop free_shipping={item.free_shipping}  cart={cart}  zipCode={selectZipCode} name={item.name} product_weight={item.weight} price={Number(item.price).toLocaleString("pt-BR", { minimumFractionDigits: 2 })} promotion_price={Number(item.promotion_price).toLocaleString("pt-BR", { minimumFractionDigits: 2 })} image={item.product_image} id={item.id} />

                                    )
                                })}
                            </tbody>

                        </table>
                        </CardTable>

                    </div>
                    <div className='container_primary_payment'>


                        <CardWrapper>

                            {cart?.map(item => {
                                return (

                                    <TableMobile name={item.name} price={Number(item.price).toLocaleString("pt-BR", { minimumFractionDigits: 2 })} image={item.product_image} variation_image={item.image} />

                                )
                            })}
                        </CardWrapper>

                    </div>
                    <ClearMobile/>

                </div>


                <div className='container_secondary_payment'>

                    <InputCupom name={"Código Cupom"} required={true} />
                    <div className='flex_container_secondary_payment'>
                        <p className='text_primary_flex_secondary_payment'>Subtotal</p>
                        <p className='text_secondary_flex_secondary_payment'>{isNaN(price) ? "...." :  Number(price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </p>
                    </div>
                    <div className='flex_container_secondary_payment'>
                        <p className='text_primary_flex_secondary_payment'>Frete</p>
                        <p className='text_secondary_flex_secondary_payment'>{animation == true ? <ReactLoading type={"spin"} color='black' width={20} />: `R$ ${Number(shippmentCartValue).toLocaleString('pt-br',{minimumFractionDigits:2})}`  }   </p>
                    </div>
                    {localStorage.getItem("@LOCAL_CART") == "[]"

                        ?
                        <></>
                        :
                        <button className='btn_primary_payment' onClick={() => handleBtnContinue()}>Continuar</button>

                    }
                    <button className='btn_secondary_payment' onClick={() => handleBtnBuy()}>Continuar comprando</button>

                </div>

            </div>
        </motion.div>
    )
}

export default ContainerPayment