import React, { useEffect, useState, useContext } from 'react'
import { CartContext } from '../../context/cart'
import api from '../../services/api'
import ReactLoading from 'react-loading';

import styled from 'styled-components'

const CardWrapper = styled.div`

    width: 50px;
    height: 50px;

    @media screen and (max-width:720px){

        
        
    }


`

const TableListModal = ({ name, image, price, id, zipCode, cart,priceAddress,promotion_price }) => {
    const { handleStateCart, handleShippmentCartValue } = useContext(CartContext)
    const [shippementValue, setShippementValue] = useState()
    const [animation, setAnimation] = useState(false)
    const [shippementState,setShippementState] = useState()

    const getShippmentValue = async () => {
        setAnimation(true)
        const reduceWeight = cart.reduce(
            (acc, item) => acc + Number(item.weight) * Number(item.quantity),
            0
        );

        const response = await api.post("/cart/get/shippement/value/", {
            "weight": reduceWeight,
            "zipCode": zipCode ? zipCode : localStorage.getItem("@PRIMARY_ADDRESS")
        })

        if (response.status == 200) {
            setTimeout(() => {
                setAnimation(false)

            }, [1500])

            if (response.data.type_delivery == "1") {
                // setShippementValue(response.data.vlTotal)
                // handleShippmentCartValue(response.data.vlTotal * cart.length)
                // return
            }
            // setShippementValue(Number(priceAddress) / cart.length)
            // // setShippementValue(response.data.vlTotal / cart.length)
            // // handleShippmentCartValue(response.data.vlTotal)

            const calc = Number(priceAddress / cart.length) 
            setShippementState(calc)
        }

    }

    useEffect(() => {
        
        getShippmentValue()
    }, [id, zipCode,priceAddress])

    return (


        <tr>
            <td style={{width:"50px"}} scope="row"><img src={image} style={{width:"60px",height:"60px"}}/></td>
            <td>{name}</td>
            <td >{promotion_price != "0" ? Number(promotion_price).toLocaleString("pt-BR", { minimumFractionDigits: 2 }) : price}</td>
            {/* <td>{ animation == true ? <ReactLoading type={"spin"} color='black' width={20} /> : Number(shippementState).toLocaleString("pt-br", { minimumFractionDigits: 2,maximumFractionDigits:2 })}</td> */}
        </tr>

    )
}

export default TableListModal