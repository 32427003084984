import React, { useEffect, useState } from 'react'
import cartIcon from '../../images/png/add-to-cart.png'
import ReactStars from 'react-stars'
import api from '../../services/api'
import { json, useNavigate } from 'react-router-dom'
const CadMenu = ({ description, price, avaliations, product_image, image, topImage, onClick, product_id, category_image, name, promotion_price, weight, index, type_stock, qtd_stock }) => {

    const [descount,setDescount] = useState()
    const navigate = useNavigate()
    
    const getPromotionalPrice = (price, promotion_price) => {
        const originalPrice = parseFloat(price.toString().replace(",", "."));
        const promotionalPrice = parseFloat(promotion_price.toString().replace(",", "."));
    
        if (originalPrice > 0 && promotionalPrice > 0) {
            // Calcula o desconto percentual
            const discountPercentage = ((originalPrice - promotionalPrice) / originalPrice) * 100;
    
            // Arredonda para baixo para pegar o valor inteiro
            const roundedDiscount = Math.floor(discountPercentage); // Use Math.round se quiser arredondar para o número inteiro mais próximo
    
            console.log(`Desconto: ${roundedDiscount}%`);
            setDescount(roundedDiscount);
        } else {
            console.log("Preço inválido ou promoção inexistente.");
            return 0;
        }
    };
    
    useEffect(() => {
        getPromotionalPrice(price,promotion_price)
    },[price,promotion_price])




    const redirectToProductPage = async () => {
        try {

            localStorage.setItem("@NAME", name);
            localStorage.setItem("@DESCRIPTION", description);
            localStorage.setItem("@PRICE", price);
            localStorage.setItem("@PROMOTIONAL_PRICE", promotion_price);
            localStorage.setItem("@WEIGHT", weight);
            localStorage.setItem("@IMAGE", image);
            localStorage.setItem("@TYPE_STOCK", type_stock);
            localStorage.setItem("@QTD_STOCK", qtd_stock);

            navigate(`/product/?id=${product_id}`)


        } catch (error) {
            console.error('Error redirecting to product page:', error);
        }
    };


    return (


        <>
            <div className='card'  >


                {!descount 
                
                ?
                <></>
                :
                <div className='descount_card' onClick={() => redirectToProductPage(description, price, image, name, promotion_price, weight)}>
                <svg width="21" height="21" viewBox="0 0 31 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.6128 0.416664L12.5874 6.6C10.4255 11.0174 7.52811 15.035 4.01905 18.4808L3.62155 18.8562C1.36787 21.0442 0.080185 24.0408 0.0440533 27.1817V27.5792C-0.0156377 33.7296 3.64939 39.3057 9.31905 41.6904L9.89322 41.9333C13.6118 43.5128 17.8126 43.5128 21.5311 41.9333H21.6636C27.3757 39.4516 31.0406 33.7845 30.9607 27.5571V17.9729C29.0572 22.3201 25.6002 25.8007 21.2661 27.7337C21.2661 27.7337 21.2661 27.7337 21.1336 27.7337C21.0011 27.7337 19.4553 28.3742 18.7928 27.7337C18.2011 27.135 18.1444 26.1906 18.6603 25.5254L18.8149 25.415H18.9253C23.9951 21.5614 25.1771 14.4213 21.6195 9.13958C18.7486 4.76708 15.6128 0.416664 15.6128 0.416664Z" fill="white" />
                </svg>
                <p>{String(descount).replace("-","")}% OFF</p>

            </div>

                }
   
                <div className='container_top_image_card' onClick={() => redirectToProductPage(description, price, image, name, promotion_price, weight)}>
                    <img className='top_image_card' loading='lazy' src={category_image} />
                </div>

                <img className='center_image_card'  loading='lazy' onClick={() => redirectToProductPage(description, price, image, name, promotion_price, weight)} src={product_image} />
                <p className='text_card_description' onClick={() => redirectToProductPage(description, price, image, name, promotion_price, weight)}>{name}</p>

                <div className='container_card_price'>
                    {promotion_price == "0"

                        ?
                        <></>
                        :
                        <p className='text_card_price' onClick={() => redirectToProductPage(description, price, image, name, promotion_price, weight)}><s>De R$ {Number(price).toLocaleString("pt-BR",{minimumFractionDigits:2})}</s></p>
                    }
                    <p className='text_card_price_promotional' onClick={() => redirectToProductPage(description, price, image, name, promotion_price, weight)}>{promotion_price == "0" ? `R$ ${Number(promotion_price).toLocaleString('pt-BR',{minimumFractionDigits:2})}` : `Por R$ ${Number(promotion_price).toLocaleString("pt-BR",{minimumFractionDigits:2})}` } </p>

                </div>
                <div className='row_button_cart'>
                    <ReactStars
                        count={5}
                        value={4.3}
                        size={24}
                        edit={false}
                        color2={'#ffd700'} />
                    <button onClick={onClick}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 3H3.26835C3.74213 3 3.97943 3 4.17267 3.08548C4.34304 3.16084 4.48871 3.28218 4.59375 3.43604C4.71269 3.61026 4.75564 3.8429 4.84137 4.30727L7.00004 16L17.4218 16C17.875 16 18.1023 16 18.29 15.9199C18.4559 15.8492 18.5989 15.7346 18.7051 15.5889C18.8252 15.4242 18.8761 15.2037 18.9777 14.7631L18.9785 14.76L20.5477 7.95996L20.5481 7.95854C20.7023 7.29016 20.7796 6.95515 20.6947 6.69238C20.6202 6.46182 20.4635 6.26634 20.2556 6.14192C20.0184 6 19.6758 6 18.9887 6H5.5M18 21C17.4477 21 17 20.5523 17 20C17 19.4477 17.4477 19 18 19C18.5523 19 19 19.4477 19 20C19 20.5523 18.5523 21 18 21ZM8 21C7.44772 21 7 20.5523 7 20C7 19.4477 7.44772 19 8 19C8.55228 19 9 19.4477 9 20C9 20.5523 8.55228 21 8 21Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                        Adicionar +</button>
                </div>

            </div>
        </>

    )
}

export default CadMenu