import React, { useEffect, useState } from 'react'
import api from '../../services/api'
/* COMPONENTS */
import Dropdown from 'react-bootstrap/Dropdown';
import TableList from '../../components/TableList';
import { Circles } from 'react-loading-icons'
import { FaBars,FaTimes  } from 'react-icons/fa'; // Importando o ícone de menu (hamburguer)

import styled from 'styled-components'
import { BigHead } from '@bigheads/core'
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import InputAccount from '../../components/InputModal';
import Button from 'react-bootstrap/Button';
import app from '../../firebase/config';
import { motion } from 'framer-motion'

/* SVG IMAGES */
import companyIcon from './images/svg/companyIcon.svg'
import adressIcon from './images/svg/adressIcon.svg'
import dashboardIcon from './images/svg/dashBoardIcon.svg'
import financialIcon from './images/svg/financialIcon.svg'
import financialCheck from './images/svg/financialCheck.svg'
import salleIcon from './images/svg/salleIcon.svg'
import dashboardCheck from './images/svg/dashboardCheck.svg'
import exitIcon from './images/svg/exitIcon.svg'
import { toast } from 'react-toastify';

const useGetRequests = () => {

    return useQuery({
        queryFn: async () => {
            const response = await api.get(`client/get/by/id`);
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('Erro ao buscar produtos');
            }
        },
        keepPreviousData: true,
    });
};

const CardWrapper = styled.section`
    display:flex;
`
const ColumPrimary = styled.div`
    transition: all .2s;
    width: 250px;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    background-color: white;
    border-right: 1px solid #D7D7D7;
    height: 100vh;

    @media screen and (max-width:720px){

        display: none;

    }

`
const ColumPrimaryOpen = styled.div`
    transition: all .2s;
    width: 250px;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    background-color: white;
    border-right: 1px solid #D7D7D7;
    height: 100vh;

    @media screen and (max-width:720px){
        position: absolute;
        width: 67%;
        z-index: 99999;
        flex:1;
        display: block;
    }

`
const HeaderColumPrimary = styled.div`
    display: flex;
    padding-top: 1.9rem;
    justify-content: center;
    align-items: center;

    img{
        width: 120px;
        border-radius: 100px;
        height: 120px;
    }

`
const ColumSecondary = styled.div`
    padding:2rem;
    flex:1;
    
    height: 100vh;

    @media screen and (max-width:720px){
        padding:0rem;
        table{
            position: fixed;
        }
    }

`
const HeaderColumSecondary = styled.div`

    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .mobile_button{
        display: none;
    }

    p{
        font-family: "Poppins", serif;
        font-weight: 600;
        font-style: normal;
        font-size: 1.87rem;
    }

    .drop_down{
        width: 234px;
        height: 45px;
        border-radius: 60px;
        border:1.9px solid rgb(215, 215, 215);
        background-color: transparent !important; /* Remove o fundo azul */
    }
    

    @media screen and (max-width:720px){
        padding-top: 2rem;
        padding-left: 0.9rem;
        padding-right: 0.9rem;
    .mobile_button{
        display: block;
    }

    p{
        font-family: "Poppins", serif;
        font-weight: 600;
        font-style: normal;
        font-size: 1.37rem;
    }

    .container_drop_down{
        display: none;
    }

    }

`
const CardItems = styled.div`
    margin-top: 3rem;
    display: flex;
    flex-direction: column;

    ul li {
        cursor: pointer;
        list-style: none;
    }

    li{
        font-family: "Poppins", serif;
        font-weight: 500;
        color: #827e8f;
        font-style: normal;
        font-size: 1rem;
        margin-bottom: 2.1rem;
    }
    
    li img {
        width: 27px;
        height: 27px;
        margin-right: 0.9rem;
    }

    .checked_item{
        padding-left: 0.3rem;
        padding-top: 0.9rem;
        padding-bottom: 0.9rem;
        width: 205px;
        display: flex;
        align-items: center;
        height: 39px;
        border-radius: 8px;
        background-color: #2f2f2f;
        color: white;
    }



`
const DropDownItems = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    color: black;

    p{
        font-size: 0.9rem;
        margin-top: 0.2rem;
        margin-left: 3rem;
        text-overflow: ellipsis;
        overflow-x: hidden;
        width: 220px;
        color: black;
        left: 5px;
        position: relative;
        overflow-x: hidden;
    }

    img{
        position: absolute; 
        width: 35px;
        height: 35px;
        border-radius: 100%;
        top: -2px;
         left: 1px;
    }



`
const ContainerProfile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

`
const ProfileCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 720px;
    border-radius: 10px;
    border:1px solid #D7D7D7;
    min-height:520px;

    @media screen and (max-width:1090px){
        width: 94%;
    }
    


`
const ProfileImage = styled.div`
    
    position: relative;

    input{
        display: none;
    }

    .camera_icon{

        position: absolute;
        right: 15px;
        bottom: 0;
    }
    img{
        margin-top: 1.5rem;
        width: 127px;
        height: 127px;
        object-fit: cover;
        border-radius: 100%;
    }
    

`
const CardInputs = styled.div`

    margin-top: 2rem;
    div > input{
        width: 420px;
        margin-top: 0px;
    }

    button{
        margin-top: 1.5rem;
        width: 100%;
        height: 43px;
        border-radius: 15px;
    }

    @media screen and (max-width:720px){

        div > input{
        width: 100%;
        margin-top: 0px;
    }

        
    }

`


const DashboardV2 = () => {
    const navigate = useNavigate()
    const [data,setData] = useState([])

    const [validFormats] = useState(["image/jpeg", "image/png"])

    const [name, setName] = useState()
    const [cpf, setCpf] = useState()
    const [phone, setPhone] = useState()


    const [pathName, setPathName] = useState()
    const [sidebar, setSidebar] = useState(false)
    const [previewImage, setPreviewImage] = useState()
    const [imageFirebase, setImageFirebase] = useState()
    const [animation,setAnimation] = useState(false)

    const redirectToHome = () => {
        navigate("/dashboard/v2")
    }
    const redirectToProfilePage = () => {
        navigate("/dashboard/v2/profile")
    }
    const redirectToAdress = () => {
        navigate("/dashboard/v2/address")
    }
    const redirectToLandingPage = () => {
        navigate("/")
    }
    const handleFile = (e) => {

        if (!e) {
            return false
        }
        const file = e.target.files[0]
        const type = file.type

        const valid = validFormats.includes(type)

        if (!valid) {
            return toast.error("Formato de imagem inválido!", {
                autoClose: 1500
            })
        }

        setImageFirebase(file)

        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setPreviewImage(reader.result)
        }


    }
    const handleImageFirebase = async () => {
        try {
            // Faz o upload da imagem para o Firebase Storage
            await app.storage().ref(`/users/pwa/profile/images/${imageFirebase.name}`).put(imageFirebase);

            // Obtém a URL de download da imagem carregada
            const imageUrl = await app.storage().ref(`/users/pwa/profile/images/${imageFirebase.name}`).getDownloadURL();

            console.log(`Imagem ${imageFirebase.name} carregada com sucesso`);
            console.log('URL da imagem:', imageUrl); // Aqui você pode usar a URL conforme necessário

            return imageUrl;  // Retorna a URL da imagem carregada
        } catch (error) {
            console.error(`Erro ao carregar a imagem ${imageFirebase}:`, error);
        }
    }
    const handleEdit = async () => {
        setAnimation(true)
        const image = await handleImageFirebase()
        const response = await api.put("client/update", {
            name: name,
            image: image,
        })

        if (response.status == 200) {
            setAnimation(false)
            localStorage.setItem("@0e63f110-145a-451e-8f8f-3404b15220c5",name)
            if(image){
                localStorage.setItem("@a951ebde-958e-4ad5-8acd-206a506ae580",image)
            }

            return toast.success("Dados editados com sucesso!", {
                autoClose: 1500
            })
        }

    }
    const handleExit = () => {
        localStorage.clear()
        window.location.href="/"
    }
    const getData =  async () => {
        const response = await api.get(`client/get/by/id`);
        setData(response.data)
    }

    useEffect(() => {
        getData()
        setPathName(window.location.pathname)
    }, [])

    useEffect(() => {
        setName(data[0]?.name)
        setCpf(data[0]?.cpf)
        setPhone(data[0]?.phone)
        setPreviewImage(data[0]?.image)
        // localStorage.setItem("@0e63f110-145a-451e-8f8f-3404b15220c5",data[0]?.name)
        // localStorage.setItem("@a951ebde-958e-4ad5-8acd-206a506ae580",data[0]?.image)
    }, [data])


    return (

        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
    >
        <CardWrapper>


            {sidebar == false

                ?
                <ColumPrimary>
                    <HeaderColumPrimary>
                        <img src={companyIcon} />
                    </HeaderColumPrimary>

                    <CardItems>

                        <ul>
                            <li className={pathName == "/dashboard/v2/profile" ? 'checked_item' : <></>} onClick={() => redirectToProfilePage()}><img src={pathName == "/dashboard/v2/profile" ? dashboardCheck : dashboardIcon} />Meu perfil</li>
                            <li className={pathName == "/dashboard/v2" ? 'checked_item' : <></>} onClick={() => redirectToHome()}><img src={pathName == "/dashboard/v2" ? financialCheck : financialIcon} />Meus pedidos</li>
                            <li className={pathName == "/dashboard/v2/address" ? 'checked_item' : <></>} onClick={() => redirectToAdress()}><img src={adressIcon} />Meus endereços</li>
                            <li onClick={() => redirectToLandingPage()}><img src={salleIcon} />Comprar</li>

                        </ul>


                    </CardItems>

                </ColumPrimary>
                :

                <ColumPrimaryOpen>
                    <HeaderColumPrimary>
                        <img src={companyIcon} />
                    </HeaderColumPrimary>

                    <CardItems>

                    <ul>
                            <li className={pathName == "/dashboard/v2/profile" ? 'checked_item' : <></>} onClick={() => redirectToProfilePage()}><img src={pathName == "/dashboard/v2/profile" ? dashboardCheck : dashboardIcon} />Meu perfil</li>
                            <li className={pathName == "/dashboard/v2" ? 'checked_item' : <></>} onClick={() => redirectToHome()}><img src={pathName == "/dashboard/v2" ? financialCheck : financialIcon} />Meus pedidos</li>
                            <li className={pathName == "/dashboard/v2/address" ? 'checked_item' : <></>} onClick={() => redirectToAdress()}><img src={adressIcon} />Meus endereços</li>
                            <li  onClick={() => redirectToLandingPage()} ><img src={salleIcon} />Comprar</li>

                        </ul>


                    </CardItems>

                </ColumPrimaryOpen>

            }


            <ColumSecondary>
                <HeaderColumSecondary>
                    <p>Meu Perfil</p>
                    <Dropdown className='container_drop_down' >
                        <Dropdown.Toggle className='drop_down' id="dropdown-basic">

                            <DropDownItems>
                                {previewImage
                                    ?
                                    <img src={previewImage} />
                                    :
                                    <div style={{ width: "50px", position: "absolute", top: "-15px", left: "-5px" }}>
                                        <BigHead
                                            accessory="shades"
                                            body="chest"
                                            circleColor="blue"
                                            clothing="tankTop"
                                            clothingColor="black"
                                            eyebrows="angry"
                                            eyes="wink"
                                            facialHair="mediumBeard"
                                            graphic="vue"
                                            hair="short"
                                            hairColor="black"
                                            hat="none"
                                            hatColor="green"
                                            lashes="false"
                                            lipColor="purple"
                                            mask="true"
                                            faceMask="true"
                                            mouth="open"
                                            skinTone="brown"
                                        />
                                    </div>
                                }

                                <p>{name}</p>
                            </DropDownItems>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleExit()}>Sair</Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                    {sidebar == false
                    
                    ?
                    <FaBars className='mobile_button' onClick={() => setSidebar(!sidebar)} style={{width:"35px",height:"35px"}}/>                
                    :
                    <FaTimes  className='mobile_button' onClick={() => setSidebar(!sidebar)} style={{width:"35px",height:"35px"}}/>
                    }
                </HeaderColumSecondary>

                <ContainerProfile>
                    <ProfileCard>
                        <ProfileImage>
                            {previewImage

                                ?
                                <img src={previewImage} />
                                :
                                <BigHead
                                    width={147}
                                    accessory="shades"
                                    body="chest"
                                    circleColor="blue"
                                    clothing="tankTop"
                                    clothingColor="black"
                                    eyebrows="angry"
                                    eyes="wink"
                                    facialHair="mediumBeard"
                                    graphic="vue"
                                    hair="short"
                                    hairColor="black"
                                    hat="none"
                                    hatColor="green"
                                    lashes="false"
                                    lipColor="purple"
                                    mask="true"
                                    faceMask="true"
                                    mouth="open"
                                    skinTone="brown"
                                />
                            }

                            <label htmlFor='input_file'>

                                <svg className='camera_icon' width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1.06152" y="0.50769" width="32.4283" height="32.2024" rx="16.1012" fill="black" stroke="#D7D7D7"></rect><path d="M21.4576 12.4653C21.0328 12.4653 20.6428 12.2232 20.4478 11.8497L19.9464 10.8467C19.626 10.2172 18.7903 9.69844 18.08 9.69844H16.4852C15.7678 9.69844 14.9321 10.2172 14.6118 10.8467L14.1104 11.8497C13.9154 12.2232 13.5254 12.4653 13.1005 12.4653C11.5893 12.4653 10.3915 13.7311 10.489 15.2253L10.8511 20.9388C10.9347 22.3638 11.7077 23.5328 13.6298 23.5328H20.9283C22.8505 23.5328 23.6165 22.3638 23.7071 20.9388L24.0692 15.2253C24.1667 13.7311 22.9689 12.4653 21.4576 12.4653ZM16.2344 13.3299H18.3237C18.6093 13.3299 18.846 13.5651 18.846 13.8487C18.846 14.1323 18.6093 14.3675 18.3237 14.3675H16.2344C15.9489 14.3675 15.7121 14.1323 15.7121 13.8487C15.7121 13.5651 15.9489 13.3299 16.2344 13.3299ZM17.2791 20.8489C15.9837 20.8489 14.9252 19.8044 14.9252 18.5109C14.9252 17.2174 15.9768 16.1729 17.2791 16.1729C18.5814 16.1729 19.633 17.2174 19.633 18.5109C19.633 19.8044 18.5744 20.8489 17.2791 20.8489Z" fill="white"></path></svg>
                            </label>
                            <input name="input_file" id="input_file" onChange={(e) => handleFile(e)} type="file" />

                        </ProfileImage>
                        <CardInputs>
                            <InputAccount onChange={(e) => setName(e.target.value)} value={name} name="Nome" />
                            <InputAccount name="CPF *" value={cpf} />
                            <InputAccount mask={"(99) 99999-9999"} onChange={(e) => setPhone(e.target.value)} name="Telefone * " value={phone} />
                            <Button variant="dark" disabled={animation} onClick={() => handleEdit()}>{animation == false ? "Editar" : <Circles width={"26px"} height={"26px"}/>} </Button>
                        </CardInputs>
                    </ProfileCard>

                </ContainerProfile>
            </ColumSecondary>
        </CardWrapper>
        </motion.div>


    )
}

export default DashboardV2