import React, { useEffect, useState } from 'react'
import api from '../../services/api'
/* COMPONENTS */
import Dropdown from 'react-bootstrap/Dropdown';
import TableList from '../../components/TableList';
import styled from 'styled-components'
import { BigHead } from '@bigheads/core'
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion'

import { FaBars,FaTimes  } from 'react-icons/fa'; // Importando o ícone de menu (hamburguer)

/* SVG IMAGES */
import companyIcon from './images/svg/companyIcon.svg'
import dashboardIcon from './images/svg/dashBoardIcon.svg'
import financialIcon from './images/svg/financialIcon.svg'
import financialCheck from './images/svg/financialCheck.svg'
import salleIcon from './images/svg/salleIcon.svg'
import adressIcon from './images/svg/adressIcon.svg'

import dashboardCheck from './images/svg/dashboardCheck.svg'
import exitIcon from './images/svg/exitIcon.svg'



const CardWrapper = styled.section`
    display:flex;
`
const ColumPrimary = styled.div`
    transition: all .2s;
    width: 250px;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    background-color: white;
    border-right: 1px solid #D7D7D7;
    height: 100vh;

    @media screen and (max-width:720px){

        display: none;

    }

`
const ColumPrimaryOpen = styled.div`
    transition: all .2s;
    width: 250px;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    background-color: white;
    border-right: 1px solid #D7D7D7;
    height: 100vh;

    @media screen and (max-width:720px){
        position: absolute;
        width: 67%;
        z-index: 99999;
        flex:1;
        display: block;
    }

`
const HeaderColumPrimary = styled.div`
    display: flex;
    padding-top: 1.9rem;
    justify-content: center;
    align-items: center;

    img{
        width: 120px;
        border-radius: 100px;
        height: 120px;
    }

`
const ColumSecondary = styled.div`
    padding:2rem;
    flex:1;
    height: 100vh;

    @media screen and (max-width:720px){
        
        .th_mobile{
            display: none;
        }
        padding:0rem;
        table{
            position: fixed;
        }


    }

`
const HeaderColumSecondary = styled.div`

    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .mobile_button{
        display: none;
    }

    p{
        font-family: "Poppins", serif;
        font-weight: 600;
        font-style: normal;
        font-size: 1.87rem;
    }

    .drop_down{
        width: 234px;
        height: 45px;
        border-radius: 60px;
        border:1.9px solid rgb(215, 215, 215);
        background-color: transparent !important; /* Remove o fundo azul */
    }
    

    @media screen and (max-width:720px){
        padding-top: 2rem;
        padding-left: 0.9rem;
        padding-right: 0.9rem;
       
    .mobile_button{
        display: block;
    }

    p{
        font-family: "Poppins", serif;
        font-weight: 600;
        font-style: normal;
        font-size: 1.37rem;
    }

    .container_drop_down{
        display: none;
    }

    }

`
const CardItems = styled.div`
    margin-top: 3rem;
    display: flex;
    flex-direction: column;

    ul li {
        cursor: pointer;
        list-style: none;
    }

    li{
        font-family: "Poppins", serif;
        font-weight: 500;
        color: #827e8f;
        font-style: normal;
        font-size: 1rem;
        margin-bottom: 2.1rem;
    }
    
    li img {
        width: 27px;
        height: 27px;
        margin-right: 0.9rem;
    }

    .checked_item{
        padding-left: 0.3rem;
        padding-top: 0.9rem;
        padding-bottom: 0.9rem;
        width: 205px;
        display: flex;
        align-items: center;
        height: 39px;
        border-radius: 8px;
        background-color: #2f2f2f;
        color: white;
    }



`
const DropDownItems = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    color: black;
    img{
        position: absolute; 
        width: 35px;
        height: 35px;
        border-radius: 100%;
        top: -2px;
         left: 1px;
    }


    p{
        font-size: 0.9rem;
        margin-top: 0.2rem;
        margin-left: 3rem;
        text-overflow: ellipsis;
        overflow-x: hidden;
        width: 220px;
        color: black;
        left: 5px;
        position: relative;
        overflow-x: hidden;
    }


`



const DashboardV2 = () => {
    const navigate = useNavigate()

    const [data,setData] = useState()

    const [pathName,setPathName] = useState()
    const [sidebar, setSidebar] = useState(false)
    const [theadData] = useState([
        {
            name: "Data",
        },
        {
            name: "Pedido",
        },
        {
            name: "Pagamento",
        },
        {
            name: "Situação",
        },
        {
            name: "Previsão Entrega",
        },
        {
            name: "Rastreio",
        },
    ])

    const redirectToHome = () => {
        navigate("/dashboard/v2")    
    }
    const redirectToProfilePage = () => {
        navigate("/dashboard/v2/profile")    
    }
    const redirectToAdress= () => {
        navigate("/dashboard/v2/address")    
    }
    const redirectToLandingPage = () => {
        navigate("/")
    }
    const handleExit = () => {
        localStorage.clear()
        window.location.href="/"
    }

    const getRequests = async () => {
        const response = await api.get("client/get/all/requests/")
        if(response.status == 404){
            setData([])
            return 
        }
        setData(response.data)
    }

    useEffect(() => {
        setPathName(window.location.pathname)
    },[])

    useEffect(() => {
        getRequests()
    },[])


    return (
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
    >
        <CardWrapper>

            {sidebar == false

                ?
                <ColumPrimary>
                    <HeaderColumPrimary>
                        <img src={companyIcon} />
                    </HeaderColumPrimary>

                    <CardItems>

                        <ul>
                            <li   className={pathName == "/dashboard/v2/profile" ? 'checked_item' : <></>} onClick={() => redirectToProfilePage()}><img src={pathName == "/dashboard/v2/profile" ? dashboardCheck : dashboardIcon } />Meu perfil</li>
                            <li  className={pathName == "/dashboard/v2" ? 'checked_item' : <></>} onClick={() => redirectToHome()}><img src={pathName == "/dashboard/v2" ? financialCheck :financialIcon } />Meus pedidos</li>
                            <li className={pathName == "/dashboard/v2/address" ? 'checked_item' : <></>} onClick={() => redirectToAdress()}><img src={adressIcon} />Meus endereços</li>
                            <li onClick={() => redirectToLandingPage()} ><img src={salleIcon} />Comprar</li>

                        </ul>


                    </CardItems>

                </ColumPrimary>
                :

                <ColumPrimaryOpen>
                    <HeaderColumPrimary>
                        <img src={companyIcon} />
                    </HeaderColumPrimary>

                    <CardItems>

                        <ul>
                            <li   className={pathName == "/dashboard/v2/profile" ? 'checked_item' : <></>} onClick={() => redirectToProfilePage()}><img src={pathName == "/dashboard/v2/profile" ? dashboardCheck : dashboardIcon } />Meu perfil</li>
                            <li  className={pathName == "/dashboard/v2" ? 'checked_item' : <></>} onClick={() => redirectToHome()}><img src={pathName == "/dashboard/v2" ? financialCheck :financialIcon } />Meus pedidos</li>
                            <li className={pathName == "/dashboard/v2/address" ? 'checked_item' : <></>} onClick={() => redirectToAdress()}><img src={adressIcon} />Meus endereços</li>
                            <li  onClick={() => redirectToLandingPage()} ><img src={salleIcon} />Comprar</li>

                        </ul>


                    </CardItems>

                </ColumPrimaryOpen>

            }


            <ColumSecondary>
                <HeaderColumSecondary>
                    <p>Meus pedidos</p>
                    <Dropdown className='container_drop_down' >
                        <Dropdown.Toggle className='drop_down' id="dropdown-basic">

                            <DropDownItems>
                                {localStorage.getItem("@a951ebde-958e-4ad5-8acd-206a506ae580") 
                                
                                ?
                                <img src={localStorage.getItem("@a951ebde-958e-4ad5-8acd-206a506ae580") } />

                                :
                                <div style={{ width: "50px", position: "absolute", top: "-15px", left: "-5px" }}>
                                <BigHead
                                    accessory="shades"
                                    body="chest"
                                    circleColor="blue"
                                    clothing="tankTop"
                                    clothingColor="black"
                                    eyebrows="angry"
                                    eyes="wink"
                                    facialHair="mediumBeard"
                                    graphic="vue"
                                    hair="short"
                                    hairColor="black"
                                    hat="none"
                                    hatColor="green"
                                    lashes="false"
                                    lipColor="purple"
                                    mask="true"
                                    faceMask="true"
                                    mouth="open"
                                    skinTone="brown"
                                />
                            </div>
                                }
                           
                                <p>{localStorage.getItem("@0e63f110-145a-451e-8f8f-3404b15220c5")}</p>
                            </DropDownItems>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleExit()}>Sair</Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                    {sidebar == false
                    
                    ?
                    <FaBars className='mobile_button' onClick={() => setSidebar(!sidebar)} style={{width:"35px",height:"35px"}}/>                
                    :
                    <FaTimes  className='mobile_button' onClick={() => setSidebar(!sidebar)} style={{width:"35px",height:"35px"}}/>
                    }
                </HeaderColumSecondary>


                <TableList
                    theadData={theadData}
                    tbodyData={data}
                />

            </ColumSecondary>

        </CardWrapper>
        </motion.div>

    )
}

export default DashboardV2