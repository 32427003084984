import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

import CardSlider from '../CardSlider'; // Importando o componente de slide

const CardWrapper = styled.div`

    width: 100%;
    height: 300px;
    margin-top: 1.2rem;
    margin-bottom: 3rem;
    @media screen and (max-width:720px){
        height: 120px;
    }

`


export default function HorizontalCarrousel({data}) {

    const handleRedirectToProductPage = (url) => {
        window.location.href=url
    }


    return (
        <CardWrapper >
            <Swiper
                direction="horizontal"
                spaceBetween={40}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: false,
                }}
                breakpoints={{
                    320: { // Tela pequena
                        slidesPerView:3,
                        spaceBetween:5,

                    },
                    640: { // Tela média
                        slidesPerView: 4,
                        spaceBetween:5,
                    },
                    768: { // Tablet
                        slidesPerView: 4,
                        spaceBetween:5,

                    },
                    1024: { // Desktop
                        slidesPerView: 3,

                    },
                }}
                
                modules={[Autoplay]}
                className="mySwiper"
            >
                {data?.map((item, index) => (
                    <SwiperSlide onClick={() => handleRedirectToProductPage(item.url)} key={index}>
                        <CardSlider image={item.image} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </CardWrapper>
    );
}
