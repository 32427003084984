import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import Container from '../../components/Container';
import Header from '../../components/Header';
import SidebarCart from '../../components/SidebarCart';
import HeaderSecondary from '../../components/HeaderSecondary';
import ContainerCard from '../../components/ContainerCard';
import VerticalCarrousel from '../../components/VerticalCarrousel';
import Card from '../../components/Card';
import toyotaLogo from '../../images/svg/toyota-logo.svg';
import { CarrouselContext } from '../../context/carrousel';
import { CartContext } from '../../context/cart';
import HeaderMobile from '../../components/HeaderMobile';
import CardCarrousel from '../../components/CardCarrousel';
import SidebarFilter from '../../components/SidebarFilterTest';
import { motion } from 'framer-motion'

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // certifique-se de importar os estilos do carousel




const useGetBannersHorizontal = () => {
    return useQuery({
        queryKey: ['bannersHorizontal'],
        queryFn: async () => {
            const response = await api.get(`banner/get/2`);
            if (response.status === 200) {
                return response.data; // Certifique-se de ajustar conforme o retorno real da API
            }
            throw new Error('Erro ao buscar banners');
        },
        keepPreviousData: true,
    });
};

const useAllProducts = (page, sessionProduct) => {

    return useQuery({
        queryKey: ['allProducts', page, sessionProduct], // A chave inclui tanto a página quanto a sessão
        queryFn: async () => {
            const response = await api.get('client/get/all/product', {
                params: {
                    page, // Passa a página
                    session_product: sessionProduct // Passa a sessão do produto
                },
            });
            if (response.status === 200) {
                return response.data.sessionProducts;
            } else {
                throw new Error('Erro ao buscar produtos');
            }
        },
    });
};

const Landing = () => {
    const navigate = useNavigate()
    const { cartLenght, handleAddToCart } = useContext(CartContext);
    const { page } = useContext(CarrouselContext)
    const [currentPage, setCurrentPage] = useState(1);
    const [bannersBottom,setBannersBottom ] = useState([])
    const { data = [], isLoading, isError, error } = useAllProducts(currentPage, 'Acessórios para carretinha');

    const { data: bannersHorizontal = [], isLoading: isLoadingBanners, isError: isErrorBanners, error: errorBanners } = useGetBannersHorizontal();


    const images = [
        {
            url:"https://firebasestorage.googleapis.com/v0/b/app-tkg.appspot.com/o/banner%2Fimages%2F0.2158302808927527?alt=media&token=367631e7-192b-4c7d-87e2-cbdc367775a0"
        },
        {
            url:"https://firebasestorage.googleapis.com/v0/b/app-tkg.appspot.com/o/banner%2Fimages%2F0.2158302808927527?alt=media&token=367631e7-192b-4c7d-87e2-cbdc367775a0"
        },
    ]

    const get = async () => {
        const response = await api.get("banner/get/3")
        if(response.status == 404){
            setBannersBottom([])
            return false
        }
        setBannersBottom(response.data)
    }

    useEffect(() => {

        get()
    },[])
    


    return (
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
    >
        <Container>
            <Header data={cartLenght} />
            <HeaderMobile />
            <HeaderSecondary />

            <SidebarFilter />

            <SidebarCart state={true} />

            <Carousel
                infiniteLoop
                autoPlay
                showThumbs={false}
            >
                {bannersHorizontal?.map((item) => {
                    const splitUrlBanner = String(item.url).split("/")
                   
                    
                    return(
                        <div
                        className="carrousel_banner_image"
                        onClick={() => {
                            navigate(`product/${splitUrlBanner[4]}`);
                        }}
                    >
                        <img src={item.image} loading="lazy" alt="Banner Primary" />
                    </div>
                    )
                })}
            </Carousel>
            {bannersBottom.length > 0 
            
            ?
            <div className='container_horizontal_carrousel'>
            <VerticalCarrousel data={bannersBottom} />
            </div>
        
            :
            <></>
            }

            {data?.map((session) => {
                // Verifica se a sessão possui produtos antes de renderizá-la
                if (session.products?.length > 0) {
                    return (
                        <>
                            <ContainerCard name={session.sessionName} data={data} /> {/* Exibe o nome da sessão */}
                            <CardCarrousel name={session.sessionName} data={data}> {/* Carrossel de produtos por sessão */}

                                {/* Filtrando os produtos para a sessão atual */}
                                {session.products?.map((item) => {
                                    return (
                                        <div key={item.product_id}>
                                            <Card
                                                onClick={() => handleAddToCart(item)}
                                                product_image={item.product_image}
                                                topImage={toyotaLogo}
                                                weight={item.weight}
                                                promotion_price={item.promotion_price}
                                                name={item.name}
                                                category_image={item.category_image}
                                                product_id={item.product_id}
                                                description={item.description}
                                                price={Number(item.price).toLocaleString('pt-br', { style: 'decimal', minimumFractionDigits: 2 })}
                                                image="https://firebasestorage.googleapis.com/v0/b/storage-tkg.appspot.com/o/2240a979b0eddbab2223d5aae5a1d99e.webp?alt=media&token=041b005b-397f-41d3-936a-ee57269e2e95"
                                                type_stock={item.type_stock}
                                                qtd_stock={item.qtd_stock}
                                            />
                                        </div>
                                    );
                                })}
                            </CardCarrousel>
                        </>
                    );
                }

                // Se a sessão não tiver produtos, não renderiza nada
                return null;
            })}


        </Container>
        </motion.div>
    );
};

export default Landing;
